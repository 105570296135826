import React, { useRef, useState } from 'react'

import { Avatar, Button, Typography, useTheme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'react-i18next'
import DropZone from '../../src/components/editEvent/DropZone'
import RestingButton from '../../src/components/editEvent/RestingButton'
import { CameraAlt } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import {
  logoutUser,
  patchUser,
  setHasAvatar,
  uploadAvatar,
} from '../../src/actions/user'
import { limitFileSize } from '../../src/utils/imageResizer'
import apiClient from '../../src/shared-components/utils/ApiClient'
import mixpanel from 'shared-components/utils/mixpanel'
import { ampli } from '../../src/ampli'
import LargeCentralPaper from '../../src/components/common/LargeCentralPaper'
import { useRouter } from 'next/router'
import BashButton, {
  BashButtonType,
} from '../../src/shared-components/buttons/BashButton'

const useProfileStyles = makeStyles()((theme) => ({
  root: {
    height: '100%',
  },
  marginTopHalf: {
    marginTop: theme.spacing(0.5),
  },
  marginTopThree: {
    marginTop: theme.spacing(3),
  },
  dropZone: {
    borderRadius: 1000,
    overflow: 'hidden',
    backgroundColor: theme.palette.grey[150],
  },
  imageContainer: {
    width: 96,
    height: 96,
    position: 'relative',
    flexGrow: 1,
    flexShrink: 0,
  },
  avatar: {
    width: '96px',
    height: '96px',
    color: theme.palette.grey[400],
    backgroundColor: theme.palette.grey[150],
  },
  editAvatar: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: 32,
    height: 32,
    minWidth: 32,
    backgroundColor: theme.palette.primary[100],
    color: theme.palette.primary[800],
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.primary[200],
    },
  },
  doubleInput: {
    // display: 'grid',
    // gridTemplateColumns: '1fr 1fr',
    // gridColumnGap: theme.spacing(2),
    // marginTop: theme.spacing(1),
    // width: '100%',
  },
  saveButton: {
    flexGrow: 1,
  },
  buttonsContainer: {
    display: 'flex',
    marginTop: theme.spacing(4),
    width: '100%',
    gap: theme.spacing(1),
  },
  container: {
    display: 'flex',
    gap: theme.spacing(3),
    alignItems: 'center',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  body1High: {
    lineHeight: '24px',
    color: theme.palette.grey[800],
  },
  signOutButtonFs: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
}))

const ProfileAvatar = ({ onDone }) => {
  const { classes } = useProfileStyles()
  const { t } = useTranslation('common')
  const user = useSelector((state) => state.user.user)
  const theme = useTheme()
  const avatarUrl = useSelector((state) => state.user.user.avatarUrls?.lg)
  const dispatch = useDispatch()
  const [img, setImg] = useState(null)
  const [saving, setSaving] = useState(false)
  const fileInputRef = useRef(null)
  const hasAvatar = user.hasAvatar || img !== null
  const router = useRouter()
  const { redirectTo } = router.query

  const onCameraClicked = () => {
    fileInputRef.current.click()
  }

  const onImageSelected = async (file) => {
    if (!file) return
    const limitedImg = await limitFileSize(file)
    setImg(limitedImg)
    mixpanel.track('Add Avatar', {
      'Personal Avatar Set': true,
      'Location in App': 'activation',
    })
  }

  const onConfirm = async () => {
    setSaving(true)
    try {
      await dispatch(uploadAvatar(img))
      ampli.addedAvatar({
        image_selection_method: 'choose_image',
        profile_location: 'ONBOARDING',
      })
      await dispatch(
        patchUser({
          ...user,
          hasAvatar: true,
        }),
      )
    } catch (e) {
      console.log(e)
    }
    dispatch(setHasAvatar(true))
    let success = false
    let retries = 0
    while (!success && retries < 5) {
      const activeUser = await apiClient.user.getMe()
      success = activeUser.hasAvatar
      retries += 1
    }
    onDone ? onDone() : router.push(redirectTo ?? '/home')
  }

  // if (!hasMounted) return null
  const body = (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.imageContainer}>
          <DropZone
            fileInputRef={fileInputRef}
            onNewFile={onImageSelected}
            overlayClassName={classes.dropZone}
          >
            <Avatar
              className={classes.avatar}
              src={
                img ? URL.createObjectURL(img) : user.hasAvatar ? avatarUrl : ''
              }
            />
          </DropZone>
          <RestingButton
            onClick={onCameraClicked}
            className={classes.editAvatar}
          >
            <CameraAlt
              style={{
                color: theme.palette.primary[800],
                width: 16,
                height: 16,
              }}
            />
          </RestingButton>
        </div>
        <div className={classes.textContainer}>
          <Typography variant='subtitle1'>
            {t('yourPersonalProfile')}
          </Typography>
          <Typography variant='body1' className={classes.body1High}>
            {t('addProfilePictureSoYourFriendsCanRecogniseYou')}
          </Typography>
        </div>
      </div>
      <div className={classes.buttonsContainer}>
        <BashButton
          type={BashButtonType.GREY}
          onClick={
            onDone ? () => onDone() : () => router.push(redirectTo ?? '/home')
          }
        >
          {t('skip')}
        </BashButton>

        <BashButton
          type={BashButtonType.PRIMARY}
          onClick={onConfirm}
          loading={saving}
          className={classes.saveButton}
          enabled={hasAvatar}
        >
          {t('saveProfile')}
        </BashButton>
      </div>
    </div>
  )

  if (!onDone) {
    return (
      <div>
        <Button
          onClick={() => {
            dispatch(logoutUser())
            router.push('/home')
          }}
          className={classes.signOutButtonFs}
        >
          {t('profileSettings.signOut')}
        </Button>
        <LargeCentralPaper>{body}</LargeCentralPaper>
      </div>
    )
  }

  return body
}
export default ProfileAvatar
