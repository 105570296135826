import { CircularProgress, Collapse, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import EnterCode from './CodeInput'
import { useSelector } from 'react-redux'
import apiClient from '../../shared-components/utils/ApiClient'
import FadingOverlay from '../FadingOverlay'

const useStyles = makeStyles()((theme) => ({
  title: {
    textAlign: 'center',
    alignSelf: 'center',
  },
  divider: {
    height: 1,
    background: theme.palette.grey[200],
    margin: theme.spacing(0, -3),
  },
  caption: {
    marginTop: theme.spacing(2),
    lineHeight: '24px',
  },
  enterCode: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  resendButton: {
    fontWeight: 500,
    cursor: 'pointer',
    marginBottom: theme.spacing(1),
  },
  titleContainer: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    gap: theme.spacing(2),
    justifyContent: 'center',
  },
  closeButton: {
    padding: 0,
    minWidth: 'auto',
    width: 24,
    height: 24,
  },
  rsvp: {
    justifyContent: 'space-between',
  },
  width: {
    width: 24,
  },
}))

export default function EnterCodeview({
  email,
  setHasSubmitted,
  onDoneLoadingUser,
}) {
  const { t } = useTranslation('common')
  const { classes } = useStyles()
  const [loading, setLoading] = useState(false)
  // const loading = true
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const organisationToFollow = useSelector(
    (state) => state.modals.organisationToFollow,
  )

  const onResend = () => {
    setHasSubmitted(false)
  }

  const onConfirm = async (code) => {
    setLoading(true)
    setError(false)
    try {
      const result = await apiClient.user.verifyCode(
        email,
        code,
        organisationToFollow?.id,
      )
      setSuccess(true)
      onDoneLoadingUser(result)
    } catch (err) {
      console.log(err)
      setError(true)
      setLoading(false)
    }
  }

  return (
    <>
      <Typography className={classes.caption} variant='body2'>
        <Trans
          t={t}
          i18nKey={'verifyEmail.caption'}
          values={{ emailAddress: email }}
          components={{ b: <b /> }}
        />
      </Typography>

      <div className={classes.enterCode}>
        <FadingOverlay
          className={undefined}
          mainContent={<EnterCode isLoading={loading} callback={onConfirm} />}
          overlay={<CircularProgress />}
          showOverlay={loading}
        />
      </div>

      <Collapse in={error && !success}>
        {/*{error && !success && (*/}
        <Typography
          variant='caption'
          style={{ marginBottom: 16 }}
          color='error'
        >
          {t('wrongSignInCode')}
        </Typography>
        {/*)}*/}
      </Collapse>

      {/*{loading && <CircularProgress />}*/}
      <Collapse in={!loading}>
        {/*{!loading && (*/}
        <div className={classes.resendButton} onClick={onResend}>
          {t('verifyEmail.resend')}
        </div>
        {/*)}*/}
      </Collapse>
    </>
  )
}
